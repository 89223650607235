// DONE: wakeup form listener on page load
// TODO: redirect users to video if the event is over
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/CleanLayout';

import RegistrationWrapper from '../../components/registration/RegistrationWrapper';
import RegistrationTitle from '../../components/registration/RegistrationTitle';
import Schedule from '../../components/registration/Webinar/Schedule';

import RSNAFormWithCal from '../../components/registration/RSNA/RSNAFormWithCal';
import styles from './rsna-2020.module.scss';
import SmoothScroll from '../../components/SmoothScroll';

const MODE = 'prod';
const SEND_EMAIL = true;
const FORM_ID = 'rsna20';

// starting v3, set owner from the DB, only pass actual form fields to api endpoint so
// that non-js users have the same experience

const METADATA = [
  {
    name: 'description',
    content: `Subtle Medical will be presenting at RSNA 2020 -- we hope to see you there!`,
  },
  {
    name: 'keywords',
    content:
      'PET, MRI, pilot study, RSNA, RSNA 2020, imaging, scans, dosage, radiology, AI, artificial intelligence, deep learning, Subtle Medical, clinical setting, deployment, medical imaging, scanners, SubtlePET, SubtleMR, COVID-19',
  },
];

// Event IDs
// AI Theater: EC5478994
// Subtle Medical Demo: Pi5478947

const IndexPage = ({ data }) => {
  // console.log(data);
  return (
    <Layout
      pageTitle="Subtle Medical | RSNA 2020 Presentations"
      metadata={METADATA}
    >
      <Img
        fluid={data.xray.childImageSharp.fluid}
        style={{
          opacity: 0.8,
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100%',
          zIndex: -3,
          pointerEvents: 'none',
        }}
      />
      <RegistrationWrapper
        className={styles.subtleinsightsRegistrationWrapper}
        backgroundImageSource={data.doodle.childImageSharp.fluid}
      >
        <div className="flex sm:flex-row flex-col justify-evenly items-center">
          <Img
            className="flex-initial w-4/5 sm:w-2/5 h-auto inline-block"
            style={{ padding: '1rem', maxWidth: '400px' }}
            imgStyle={{
              padding: '1rem',
              margin: 'auto',
            }}
            fluid={data.rsnaBanner.childImageSharp.fluid}
          />
          <a
            className="flex-initial w-4/5 sm:w-2/5 h-auto inline-block"
            href="https://subtlemedical.com/"
          >
            <Img
              style={{ maxWidth: '400px', margin: 'auto', marginTop: '1rem' }}
              fluid={data.subtlelogo.childImageSharp.fluid}
            />
          </a>
        </div>
        <RegistrationTitle className={styles.webinarTitleWrapper}>
          <p className={styles.webinarTitle}>
            <span style={{ fontSize: '80%' }}>Connect With Us At</span>
            <br />
            RSNA 2020
          </p>
        </RegistrationTitle>

        <p
          className={styles.webinarSubtitle}
          style={{ padding: '2rem 2rem 0 2rem' }}
        >
          Subtle Medical makes FDA-approved AI software to enhance the quality
          of your PET and MR scans.
        </p>
        <p className={styles.webinarSubtitle} style={{ padding: '0 2rem' }}>
          Connect with us at RSNA to learn about the latest advances in AI
          imaging technology and to see how hospitals & imaging centers are
          using Subtle to increase their operational efficiency and patient
          satisfaction.
        </p>

        <div className="mt-5 px-8 sm:mt-8 flex flex-row justify-center items-baseline">
          <div className="py-3 md:py-4">
            <SmoothScroll
              targetId="#meeting"
              className="shadow-md hover:shadow-none border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 visited:outline-none active:outline-none focus:outline-none transition duration-150 ease-in-out md:text-xl p-3"
            >
              Book a Meeting
            </SmoothScroll>
          </div>
        </div>

        <h2 id="presentations" className={`mt-24 ${styles.sectionTitle}`}>
          Presentations
        </h2>
        <div className={styles.webinarDivider} />

        <div className={`${styles.talkContainer} items-start`}>
          <Schedule className={styles.webinarScheduleContainer}>
            <h3 className={styles.talkTitle}>Featured Demonstration</h3>
            <div style={{ lineHeight: '1.75rem', marginTop: '6px' }}>
              <div style={{ fontSize: '80%' }}>Dec 3rd, 10:00 AM,<br/>and on demand thereafter</div>
              {/* <div style={{ fontSize: '80%' }}>&mdash; Channel 6 &mdash;</div> */}
            </div>
          </Schedule>

          <Schedule className={styles.webinarScheduleContainer}>
            <h3 className={styles.talkTitle}>AI Theater Presentation</h3>
            <div style={{ lineHeight: '1.5rem', marginTop: '10px' }}>
              <div style={{ fontSize: '80%' }}>On demand</div>
              {/* <div style={{ fontSize: '80%' }}>&mdash; AI19 &mdash;</div> */}
            </div>
          </Schedule>
        </div>

        <div className="mt-5 px-8 sm:mt-8 flex flex-row justify-center items-baseline">
          <div className="py-3 md:py-4">
            <SmoothScroll
              targetId="#meeting"
              className="shadow-md hover:shadow-none border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 visited:outline-none active:outline-none focus:outline-none transition duration-150 ease-in-out md:text-xl p-3"
            >
              Register
            </SmoothScroll>
          </div>
        </div>

        <h2 className={`mt-16 ${styles.sectionTitle}`}>
          Scientific Poster Presentations
        </h2>
        <div className={styles.webinarDivider} />

        <div className={styles.paperContainer}>
          <div className={styles.paper}>
            Clinical Validation of AI-enhanced PET Images: Are Ultra-fast PET
            Acquisitions Feasible?{' '}
            <span className="italic text-sm text-gray-700">
              Event ID: 20016223
            </span>
          </div>
          <div className={styles.paper}>
            Real-World External Clinical Validation of Deep-Learning-Based
            Fourfold Low-Count Positron Emission Tomography: A Blinded,
            Multi-Center Study{' '}
            <span className="italic text-sm text-gray-700">
              Event ID: 20012620
            </span>
          </div>
          <div className={styles.paper}>
            Deep Learning Reconstructed, 40% Faster Spine MR Examinations Match
            or Exceed the Quality of Standard of Care Exams{' '}
            <span className="italic text-sm text-gray-700">
              Event ID: 20011862
            </span>
          </div>
          <div className={styles.paper}>
            Deep Learning Enables 18F-FDG PET Scans of Pediatric Lymphoma
            Patients with 50% Reduced Radiotracer Dose{' '}
            <span className="italic text-sm text-gray-700">
              Event ID: 20013685
            </span>
          </div>
        </div>

        <div className="px-8 mt-8">
          <SmoothScroll targetId="#meeting">Sign up</SmoothScroll> to be
          notified once these Scientific Posters are available.
        </div>
        {/* <div className={styles.webinarDivider} /> */}

        {/*
      Event ID #:
      20016223:  Clinical Validation of AI-enhanced PET Images:  Are Ultra-fast PET Acquisitions Feasible?
      20012620:  Real-World External Clinical Validation of Deep-Learning-Based Fourfold Low-Count Positron Emission 
                        Tomography:  A Blinded, Multi-Center Study
      20011862:  Deep Learning Reconstructed, 40% Faster Spine MR Examinations Match or Exceed the Quality of Standard of 
                        Care Exams
      20013685:  Deep Learning Enables 18F-FDG PET Scans of Pediatric Lymphoma Patients with 50% Reduced Radiotracer Dose  
        */}

        <div
          style={{ paddingTop: '1rem' }}
          className={styles.subtleinsightsFlexWrapper}
        >
          <div id="meeting" className={styles.subtleinsightsFlexRight}>
            <RSNAFormWithCal
              eventId="bZ4815073"
              minHeight="670" // px so we can easily offset from top; needed to keep from jangling on state change
              MODE={MODE}
              SEND_EMAIL={SEND_EMAIL}
              FORM_ID={FORM_ID}
            />
          </div>
        </div>
        <div className={`mt-24 ${styles.sectionTitle} text-lg`}>
          We look forward to seeing you at RSNA 2020!
        </div>
      </RegistrationWrapper>
    </Layout>
  );
};

export const query = graphql`
  {
    xray: file(relativePath: { eq: "resources/mast-insights-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doodle: file(relativePath: { eq: "resources/subtle-medical-overlay.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, grayscale: false) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    subtlelogo: file(
      relativePath: { eq: "resources/subtle-medical-logo-clean.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rsnaBanner: file(
      relativePath: { eq: "resources/RSNA/rsna2020-640x214.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tanenbaum: file(
      relativePath: { eq: "resources/Webinars/7/lawrence-tanenbaum-md.png" }
    ) {
      childImageSharp {
        fixed(width: 200, height: 200, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bg: file(relativePath: { eq: "resources/bg/subtle_medical_bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
