import React from 'react';
import { head, tail } from 'ramda';

const SmoothScroll = props => {
  const {
    targetId,
    children,
    className = 'text-blue-600 underline',
    style = {},
  } = props;

  const target = head(targetId) === '#' ? tail(targetId) : targetId;

  const smoothScroll = id => e => {
    try {
      const el = document.getElementById(id);
      if (!el) return;

      const { top } = el.getBoundingClientRect();
    
      // getBoundingClientRect returns offsets from current viewport -- use the viewport scroll offset to get an absolute value
      window.scrollTo({ behavior: 'smooth', top: top + window.scrollY, left: 0 });

      e.preventDefault();
    } catch (err) {
      return;
    }
  };

  return (
    <a
      className={className}
      href={`#${target}`}
      style={style}
      onClick={smoothScroll(target)}
    >
      {children}
    </a>
  );
};

export default SmoothScroll;
