/* eslint-disable import/no-extraneous-dependencies */
/* global fetch */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormField,
  FormSelect,
  FormButton,
  IfResolved,
  IfFailed,
  IfSubmitting,
  IfActive,
  FormCheckbox,
  // FormStatusWrapper,
} from '../../../../react-pretty-forms';
import BallLoader from '../../loaders/BallLoader';
import Retry from '../Retry';
import styles from './rsnaForm.module.scss';
import { LATEST } from '../version';
import persistentSubmit from '../../componentLogic/persistentSubmit';
import AddEvent from '../Webinar5/AddEvent';

// import ControlPanel from '../StateToggler';
// import FormTitle from '../FormTitle';

// starting v3, set owner from the DB, only pass actual form fields to api endpoint so
// that non-js users have the same experience

export default function RegistrationFormWithCalendar({
  MODE,
  SEND_EMAIL,
  FORM_ID,
  eventId,
  minHeight,
}) {
  const ENDPOINT = {
    prod: `${LATEST}/${FORM_ID}/${SEND_EMAIL ? 'notify' : 'silent'}`,
    dev: `http://localhost:5000/subtleinsights/us-central1/subtleForms/${FORM_ID}/${
      SEND_EMAIL ? 'notify' : 'silent'
    }`,
    test: `http://localhost:5000/subtleinsights/us-central1/subtleForms/${FORM_ID}/wakeup/`,
    wakeup: `${LATEST}/${FORM_ID}/wakeup/`,
  };

  useEffect(() => {
    // wake up endpoint
    fetch(ENDPOINT.wakeup, {
      method: 'POST',
    }).then(() => {
      console.log('Endpoint woken');
    });
  }, []);

  const formRef = useRef(null);

  return (
    <div id="register" className={styles.flexFormBottom}>
      <div
        className={styles.formBoxBottom}
        style={{ minHeight: `${minHeight}px` }}
      >
        <Form
          action={ENDPOINT[MODE]}
          wakeup
          submit={persistentSubmit}
          cssModule={styles}
          ref={formRef}
        >
          <IfActive>
            <h2 className="px-6 mb-4 text-lg font-bold text-green-600">
              Connect at RSNA
            </h2>
            <FormSelect
              name="salutation"
              label="Preferred salutation"
              defaultValue="unselected"
            >
              <option value="">-</option>
              <option value="Dr.">Dr.</option>
              <option value="Prof.">Prof.</option>
            </FormSelect>
            <FormField type="text" name="first" label="First name*" required />
            <FormField type="text" name="last" label="Last name*" required />
            <FormField type="text" name="role" label="Job Title" />
            <FormField
              type="text"
              name="institution"
              label="Institution*"
              required
            />
            <FormField type="email" name="email" label="Email*" required />
            <FormCheckbox
              type="checkbox"
              name="attendDemo"
              label="Register for presentations"
              value="Yes"
            />
            <FormCheckbox
              type="checkbox"
              name="attendAiTheater"
              label="Send links to scientific posters"
              value="Yes"
            />
            <FormCheckbox
              type="checkbox"
              name="bookMeeting"
              label="Book a meeting"
              value="Yes"
            />
            <FormField type="hidden" name="source" initialValue="direct" />
            <FormButton
              className="shadow-md hover:shadow-none border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 hover:bg-green-600 visited:outline-none active:outline-none focus:outline-none transition duration-150 ease-in-out md:text-xl p-3"
              type="submit"
              value="Submit"
            />
            {/* <div style={{ paddingTop: '1rem', fontSize: '80%' }}>
              We promise not to share your information.
            </div> */}
          </IfActive>
          <IfResolved>
            <div
              className={styles.thankyouComponent}
              style={{
                position: 'absolute',
                top: '40%',
                transform: 'translateY(-50%)',
              }}
            >
              Thank you!
              <br />
              <br />
              <span
                style={{
                  fontSize: '80%',
                  lineHeight: '1.5rem',
                  display: 'inline-block',
                  paddingBottom: '2rem',
                }}
              >
                We'll reach out to you soon to schedule a meeting at RSNA.
              </span>
              <AddEvent
                eventId="EC5478994"
                text="Add Subtle Medical AI Theater Presentation to Calendar"
              />
              <AddEvent
                eventId="Pi5478947"
                text="Add Subtle Medical RSNA Demo to Calendar"
              />
            </div>
          </IfResolved>
          <IfSubmitting>
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '40%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
            >
              <BallLoader />
              Submitting...
            </div>
          </IfSubmitting>
          <IfFailed>
            <div
              style={{
                position: 'absolute',
                top: '40%',
                transform: 'translateY(-50%)',
              }}
            >
              <Retry form={formRef} />
            </div>
          </IfFailed>
          {/* <FormStatusWrapper>
                <ControlPanel />
              </FormStatusWrapper> */}
        </Form>
      </div>
    </div>
  );
}
